<script setup>
import config from "../config";
import { onMounted, onUnmounted, computed, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { enGB, ru } from "date-fns/locale";
import { Chart } from "highcharts-vue";
import { useRouter } from 'vue-router'
import { useCookies } from "vue3-cookies"
import { mdiConnection, mdiMagnifyExpand, mdiClose, mdiArrowDown, mdiArrowUp } from '@mdi/js'

const { cookies } = useCookies();
const router = useRouter()
const { t, locale } = useI18n();
const store = useStore();
const { proxy } = getCurrentInstance();
const languages = ref({
  eu: enGB,
  ru: ru,
});

const status = computed({
  get() {
    return store.state.status;
  },
  set(value) {
    store.commit("SET_STATUS", value);
  },
});

const stats = computed({
  get() {
    return store.state.home;
  },
  set(value) {
    store.commit("SET_HOME", value);
  },
});

const hashrate = computed({
  get() {
    let push = store.state.home.chart;
    let val = [];
    push.forEach(function (d) {
      var y = d.h;
      var x = d.t;
      val.push({
        y: y,
        x: x,
      });
    });
    return val;
  },
  set(value) {
    store.commit("SET_HOME", value);
  },
});

const difficulty = computed({
  get() {
    let push = store.state.home.chart;
    let val = [];
    push.forEach(function (d) {
      var y = d.d;
      var x = d.t;
      val.push({
        y: y,
        x: x,
      });
    });
    return val;
  },
  set(value) {
    store.commit("SET_HOME", value);
  },
});

let input = ref(cookies.get(`${config.coin}-${config.scheme}`));

function submitForm() {
  if (input.value != null) {
    let wallet = input.value.split(':');
    if (wallet.length > 1) {
      wallet = wallet[1]
    }
    cookies.set(`${config.coin}-${config.scheme}`, wallet)
    router.push(`/account/${wallet}`);
  }
}

function clearForm() {
  cookies.remove(`${config.coin}-${config.scheme}`);
}

const chartOptions = computed(() => {
  return {
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: {
          duration: 0,
        },
      },
      areaspline: {
        marker: {
          enabled: false,
        },
      },
    },
    chart: {
      type: "areaspline",
      height: 280,
      ignoreHiddenSeries: false,
      backgroundColor: "#292929",
      borderColor: "#292929",
      borderWidth: 1,
      borderRadius: 0,
      spacing: [4, 4, 4, 4],
      alignTicks: false,
      animation: false,
      style: {
        fontFamily: "Nunito",
      },
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      title: {
        enabled: false,
      },
      tickAmount: 8,
      lineWidth: 0,
      tickColor: "rgba(255, 255, 255, 0.1)",
      labels: {
        formatter: function () {
          let time = 0;
          if (this.value != -1 && this.value != 0) {
            time = proxy.$filters
              .timestamp(this.value)
              .toLocaleTimeString(navigator.language, {
                hour: "numeric",
                minute: "numeric",
              });
          }
          return time;
        },
        style: {
          fontFamily: "Nunito",
          color: "rgba(255, 255, 255, 0.8)",
        },
      },
    },
    yAxis: [
      {
        title: {
          enabled: false,
        },
        gridLineColor: "rgba(255, 255, 255, 0.1)",
        min: 0,
        tickAmount: 5,
        labels: {
          formatter: function () {
            return proxy.$filters.hashrate(this.value, 0);
          },
          style: {
            fontFamily: "Nunito",
            color: "rgba(255, 255, 255, 0.8)",
          },
        },
      },
      {
        title: {
          enabled: false,
        },
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        tickPositioner: function () {
          return [
            0.9 * this.dataMin,
            this.dataMin,
            this.dataMax,
            1.2 * this.dataMax,
          ];
        },
      },
    ],
    tooltip: {
      animation: false,
      shared: true,
      shadow: false,
      headerFormat: t("time") + ": ",
      style: {
        fontFamily: 'Nunito',
        color: "rgba(0, 0, 0, 0.8)",
        fontSize: ".7rem",
        fontWeight: "500"
      },
      crosshairs: true,
    },
    legend: {
      enabled: true,
      align: "center",
      verticalAlign: "top",
      itemStyle: {
        color: "rgba(255, 255, 255, 0.8)",
        fontFamily: 'Nunito',
        fontSize: ".8rem",
        fontWeight: "500"
      },
      itemHoverStyle: {
        color: "#fafafa",
      },
    },
    series: [
      {
        yAxis: 0,
        name: t("pool_hashrate"),
        data: hashrate.value,
        color: "#00507e",
        lineWidth: 2,
        states: {
          inactive: {
            enabled: false,
          },
        },
        tooltip: {
          pointFormatter: function () {
            return (
              proxy.$filters
                .timestamp(this.x)
                .toLocaleTimeString(navigator.language, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }) +
              "<br> " +
              t("pool_hashrate") +
              ": " +
              proxy.$filters.hashrate(this.y, 2)
            );
          },
        },
        fillColor: false,
      },
      {
        yAxis: 1,
        name: t("network_difficulty"),
        data: difficulty.value,
        color: "#e78400",
        lineWidth: 0.5,
        states: {
          inactive: {
            enabled: false,
          },
        },
        tooltip: {
          pointFormatter: function () {
            return (
              "<br> " +
              t("network_difficulty") +
              ": " +
              proxy.$filters.difficulty(this.y, 2)
            );
          },
        },
        fillColor: {
          linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1,
          },
          stops: [
            [0, "rgba(231, 132, 0, 0.2)"],
            [1, "rgba(231, 132, 0, 0.0)"],
          ],
        },
      },
    ],
  };
});

let intervalId;
onMounted(() => {
  store.dispatch("fetchHome");
  intervalId = setInterval(() => {
    store.dispatch("fetchHome");
  }, 10000);
});
onUnmounted(() => clearInterval(intervalId));
</script>
<template>
  <v-row dense>
    <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
      <v-card class="card" variant="outlined">
        <v-card-title>
          {{ $t("pool_stats") }}
        </v-card-title>
        <v-divider class="dashed"></v-divider>
        <v-row no-gutters>
          <v-col>
            <div class="card-subtitle">
              {{ $t("miners") }}
            </div>
            <div class="card-text">
              <span v-if="status === 'success'">
                {{ $filters.number(stats.totalMiners) }}
              </span>
              <span v-else>
                <v-skeleton-loader pill height="24" />
              </span>
            </div>
          </v-col>
          <v-divider vertical class="dashed"></v-divider>
          <v-col>
            <div class="card-subtitle">
              {{ $t("hashrate") }}
            </div>
            <div class="card-text">
              <span v-if="status === 'success'">
                <span v-html="$filters.hashrate(stats.totalHashrate, 2)"></span>
              </span>
              <span v-else>
                <v-skeleton-loader pill height="24" />
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
      <v-card class="card" variant="outlined">
        <v-card-title>
          {{ $t("blocks_stats") }}
        </v-card-title>
        <v-divider class="dashed"></v-divider>
        <v-row no-gutters>
          <v-col>
            <div class="card-subtitle">
              {{ $t("current_effort") }}
            </div>
            <div class="card-text">
              <span v-if="status === 'success'">
                <span v-if="stats.currentEffort >= 1.0" class="text-blue">
                  {{ $filters.effort(stats.currentEffort, 0) }}
                </span>
                <span v-else class="text-green">
                  {{ $filters.effort(stats.currentEffort, 0) }}
                </span>
              </span>
              <span v-else>
                <v-skeleton-loader pill height="24" />
              </span>
            </div>
          </v-col>
          <v-divider vertical class="dashed"></v-divider>
          <v-col>
            <div class="card-subtitle">
              {{ $t("last_block") }}
            </div>
            <div class="card-text text-truncate">
              <span v-if="status === 'success'">
                <span v-if="stats.lastBlockFound != null">
                  <timeago :key="locale" :locale="languages[locale]" :datetime="stats.lastBlockFound * 1000" />
                </span>
                <span v-else>
                  {{ $t("no_data") }}
                </span>
              </span>
              <span v-else>
                <v-skeleton-loader pill height="24" />
              </span>
            </div>
          </v-col>
          <v-divider vertical class="dashed"></v-divider>
          <v-col>
            <div class="card-subtitle">
              {{ $t("blocks_found") }}
            </div>
            <div class="card-text">
              <span v-if="status === 'success'">
                {{ $filters.number(stats.totalBlocks) }}
              </span>
              <span v-else>
                <v-skeleton-loader pill height="24" />
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
      <v-card class="card" variant="outlined">
        <v-card-title>
          {{ $t("network_statistics") }}
        </v-card-title>
        <v-divider class="dashed"></v-divider>
        <v-row no-gutters>
          <v-col>
            <div class="card-subtitle">
              {{ $t("network_difficulty") }}
            </div>
            <div class="card-text">
              <span v-if="status === 'success'">
                <span v-html="$filters.difficulty(stats.networkDifficulty, 3)"></span>
              </span>
              <span v-else>
                <v-skeleton-loader pill height="24" />
              </span>
            </div>
          </v-col>
          <v-divider vertical class="dashed"></v-divider>
          <v-col>
            <div class="card-subtitle">
              {{ $t("network_hashrate") }}
            </div>
            <div class="card-text">
              <span v-if="status === 'success'">
                <span v-html="$filters.hashrate(stats.networkHashrate, 2)"></span>
              </span>
              <span v-else>
                <v-skeleton-loader pill height="24" />
              </span>
            </div>
          </v-col>
          <v-divider vertical class="dashed"></v-divider>
          <v-col>
            <div class="card-subtitle">
              {{ $t("current_height") }}
            </div>
            <div class="card-text">
              <span v-if="status === 'success'">
                {{ $filters.number(stats.currentHeight) }}
              </span>
              <span v-else>
                <v-skeleton-loader pill height="24" />
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
      <v-card class="card" variant="outlined">
        <v-card-title>
          {{ $t("price") }}
        </v-card-title>
        <v-divider class="dashed"></v-divider>
        <v-row no-gutters>
          <v-col>
            <div class="card-subtitle">
              {{ $t("price_symbol", { name: "USD" }) }}
            </div>
            <div class="card-text">
              <span v-if="status === 'success'">
                {{ $filters.price(stats.usd, 8) }}
                <small v-if="stats.btc_24h_change[0] == '-'" class="text-red ml-1">
                  {{ $filters.price_percent(stats.usd_24h_change) }}
                  <v-icon right size="14" class="mx-0 text-red" :icon="mdiArrowDown"></v-icon>
                </small>
                <small v-else class="text-green ml-1">
                  +{{ $filters.price_percent(stats.usd_24h_change) }}
                  <v-icon right size="14" class="mx-0 text-green" :icon="mdiArrowUp"></v-icon>
                </small>
              </span>
              <span v-else>
                <v-skeleton-loader pill height="24" />
              </span>
            </div>
          </v-col>
          <v-divider vertical class="dashed"></v-divider>
          <v-col>
            <div class="card-subtitle">
              {{ $t("price_symbol", { name: "BTC" }) }}
            </div>
            <div class="card-text">
              <span v-if="status === 'success'">
                {{ $filters.reward(stats.btc, 8) }}
                <small v-if="stats.btc_24h_change[0] == '-'" class="text-red ml-1">
                  {{ $filters.price_percent(stats.btc_24h_change, 2) }}
                  <v-icon right size="14" class="mx-0 text-red" :icon="mdiArrowDown"></v-icon>
                </small>
                <small v-else class="text-green ml-1">
                  +{{ $filters.price_percent(stats.btc_24h_change, 2) }}
                  <v-icon right size="14" class="mx-0 text-green" :icon="mdiArrowUp"></v-icon>
                </small>
              </span>
              <span v-else>
                <v-skeleton-loader pill height="24" />
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-form @submit.prevent="submitForm">
        <v-text-field v-model="input" @click:prepend-inner="submitForm" variant="solo" :placeholder="$t('input_wallet')"
          density="comfortable" clearable @click:clear="clearForm" single-line hide-details
          :prepend-inner-icon="mdiMagnifyExpand" :clear-icon="mdiClose">
        </v-text-field>
      </v-form>
    </v-col>
    <v-col cols="12" class="">
      <v-card class="card" variant="outlined">
        <span v-if="status === 'success'">
          <Chart :key="locale" :options="chartOptions"></Chart>
        </span>
        <span v-else>
          <v-skeleton-loader class="rounded" height="280" />
        </span>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
      <v-card class="card" variant="outlined">
        <v-card-title class="v-card-title-one">
          {{ $t("algorithm") }}
        </v-card-title>
        <v-divider class="dashed"></v-divider>
        <div class="card-text-one">
          <span v-if="status === 'success'">
            {{ stats.algo }}
          </span>
          <span v-else>
            <v-skeleton-loader pill height="24" />
          </span>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
      <v-card class="card" variant="outlined">
        <v-card-title class="v-card-title-one">
          {{ $t("calculation_scheme") }}
        </v-card-title>
        <v-divider class="dashed"></v-divider>
        <div class="card-text-one">
          <span v-if="status === 'success'">
            {{ stats.scheme }}
          </span>
          <span v-else>
            <v-skeleton-loader pill height="24" />
          </span>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
      <v-card class="card" variant="outlined">
        <v-card-title class="v-card-title-one">
          {{ $t("block_reward") }}
        </v-card-title>
        <v-divider class="dashed"></v-divider>
        <div class="card-text-one">
          <span v-if="status === 'success'">
            {{ $filters.reward(stats.reward, 3) }}
            <span class="sub">{{ config.coin }}</span>
            <span class="ml-1 text-green">
              ({{ $filters.price(stats.reward * stats.usd) }})
            </span>
          </span>
          <span v-else>
            <v-skeleton-loader pill height="24" />
          </span>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
      <v-card class="card" variant="outlined">
        <v-card-title class="v-card-title-one">
          {{ $t("pool_fee") }}
        </v-card-title>
        <v-divider class="dashed"></v-divider>
        <div class="card-text-one">
          <span v-if="status === 'success'"> {{ stats.fee }}% </span>
          <span v-else>
            <v-skeleton-loader pill height="24" />
          </span>
        </div>
      </v-card>
    </v-col>
  </v-row>
  <v-row align="center" justify="center">
    <v-col class="text-center" cols="12">
      <v-btn color="#227929" flat rounded :prepend-icon="mdiConnection" to="/help">
        {{ $t("learn_more_how_connect") }}
      </v-btn>
    </v-col>
  </v-row>
</template>