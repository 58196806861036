export default {
    name: "Bitcoin Cash",
    coin: "BCH",
    scheme: "SOLO",
    account: 'bitcoincash:qqva9g3j8de4evpt95p4y4h5c3lwuw42gc3nwrq9ms',
    explorer_url: 'https://blockchair.com/bitcoin-cash',
    coingecko: 'https://www.coingecko.com/en/coins/bitcoin-cash',
    explorer: {
        address: "https://blockchair.com/bitcoin-cash/address/",
        height: "https://blockchair.com/bitcoin-cash/block/",
        hash: "https://blockchair.com/bitcoin-cash/block/",
        tx: "https://blockchair.com/bitcoin-cash/transaction/",
    },
    ports: [
        {
            info: "LOW",
            host: "molepool.com",
            port: 5566,
            diff: 250000,
        },
        {
            info: "MID",
            host: "molepool.com",
            port: 5567,
            diff: 1000000,
        },
        {
            info: "HIGH",
            host: "molepool.com",
            port: 5568,
            diff: 10000000,
        }
    ]
};